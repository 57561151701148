var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _c("Navbar"),
      _c("main", [
        _c("div", { staticClass: "container-wrapper" }, [
          _c("div", { staticClass: "page-content-div" }, [
            _c("div", { staticClass: "center-container" }, [
              _c("div", { staticClass: "content-title" }, [
                _vm._v(
                  "\n            You were invited by\n            " +
                    _vm._s(
                      _vm.user.teamLeadName
                        ? _vm.user.teamLeadName.split(" ")[0]
                        : ""
                    ) +
                    " to\n            join\n            "
                ),
                _c("span", [_vm._v(_vm._s(_vm.teamName))]),
              ]),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(
                  "\n            Accepting this invite means you’ll be removed from your existing\n            team.\n          "
                ),
              ]),
              _c(
                "div",
                { staticClass: "card-container" },
                _vm._l(_vm.options, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "option-card",
                      on: {
                        click: function ($event) {
                          return _vm.handleSwapTeam(item.id)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "left-content" }, [
                        _c("div", { staticClass: "card-name" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                        ]),
                        item.id == 0
                          ? _c("div", { staticClass: "card-content" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(item.content) +
                                  "\n                "
                              ),
                            ])
                          : _c("div", { staticClass: "card-content" }, [
                              _vm._v(
                                "\n                  Accept Invite and join " +
                                  _vm._s(_vm.teamName) +
                                  " to build amazing\n                  products on Grupa\n                "
                              ),
                            ]),
                      ]),
                      _vm._m(0, true),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-content" }, [
      _c("img", { attrs: { src: "/img/onboarding/right-arrow.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }