var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.page_loader
        ? _c(
            "div",
            {
              staticStyle: {
                left: "50%",
                right: "0",
                margin: "auto",
                "margin-top": "10%",
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { size: 30, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.page_loader && !_vm.dialog
        ? _c(
            "div",
            [
              _vm.user && !_vm.user.invited_team_id
                ? _c(
                    "SignUpComponent",
                    _vm._b({}, "SignUpComponent", { user: _vm.user }, false)
                  )
                : _vm._e(),
              _vm.user && _vm.user.invited_team_id
                ? _c(
                    "SwapTeam",
                    _vm._b(
                      { attrs: { invitedTeam: _vm.user.invited_team } },
                      "SwapTeam",
                      { user: _vm.user, handleSwapTeam: _vm.handleSwapTeam },
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      !_vm.page_loader && _vm.dialog
        ? _c(
            "ErrorModal",
            _vm._b(
              {},
              "ErrorModal",
              { dialog_message: _vm.dialog_message },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }