<template>
  <div>
    <div
      style="left: 50%; right: 0; margin: auto; margin-top: 10%"
      v-if="page_loader"
    >
      <v-progress-circular
        :size="30"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-if="!page_loader && !dialog">
      <SignUpComponent v-if="user && !user.invited_team_id" v-bind="{ user }" />
      <SwapTeam
        v-if="user && user.invited_team_id"
        v-bind="{ user, handleSwapTeam }"
        v-bind:invitedTeam="user.invited_team"
      />
    </div>

    <ErrorModal v-if="!page_loader && dialog" v-bind="{ dialog_message }" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Axios from "axios";
import SignUpComponent from "@/views/auth/signup";
import ErrorModal from "./partials/errorModal.vue";
import SwapTeam from "./partials/SwapTeam.vue";
export default {
  props: {},
  components: {
    SignUpComponent,
    ErrorModal,
    SwapTeam,
  },
  data: () => ({
    page_loader: true,
    valid_url: true,
    dialog: false,
    dialog_message: "",
    user: {},
  }),

  created() {
    if (this.$route.params.reg_token) {
      this.validateUrl();
      this.setRegistrationToken(this.$route.params.reg_token);
      this.setCustomerType(1);
    } else {
      this.page_loader = false;
      this.dialog = true;
    }
  },
  methods: {
    ...mapActions("devOnboarding", ["setRegistrationToken"]),
    ...mapActions("userManagement", ["setLinkedinLogin", "setCustomerType"]),

    handleLinkedinSignUp() {
      this.setLinkedinLogin("teamMember");
    },

    validateUrl() {
      Axios.get(
        this.$baseUrl +
          "/onboarding/dev/accept_invite/" +
          this.$route.params.reg_token
      )
        .then((response) => {
          if (response.data.status == 1) {
            // this.form.email = response.data.data.email;
            // this.updateUserEmail(this.form.email);
            this.user = response.data.data;
            this.$developConsole(response.data.data, "Team Details");
          } else if (response.data.status == 0) {
            this.$developConsole(response.data, "Error Registration Link");
            this.valid_url = false;
            this.dialog = true;
            this.dialog_message = response.data.msg;
          }
          this.page_loader = false;
        })
        .catch((err) => {
          this.$developConsole(
            err.response.data.status,
            "Error Registration Link"
          );
          if (err.response.data.status == 0) {
            this.valid_url = false;
            this.dialog = true;
            this.dialog_message = err.response.data.msg;
          }
          this.page_loader = false;
        });
    },
    handleSwapTeam(action) {
      this.page_loader = true;
      const payload = { action };
      Axios.post(
        this.$baseUrl +
          "/onboarding/dev/swap_invite/" +
          this.$route.params.reg_token,
        payload
      )
        .then((response) => {
          if (response.data.status == 0) {
            this.dialog = true;
            this.dialog_message = response.data.msg;
          }
          if (response.data.status == 1) {
            this.$router.push({ name: "dev_dashboard" });
          }
          this.page_loader = false;
        })
        .catch((error) => {});
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
</style>
