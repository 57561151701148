var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/img/profile/grupa-logo.png",
                      height: "60",
                      width: "120",
                    },
                  }),
                ]
              ),
              _c("v-subheader", [_c("b", [_vm._v("Ooops!")])]),
              _c("v-card-text", [
                _c("p", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(_vm.dialog_message)),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("a", { attrs: { href: "/" } }, [
                    _vm._v("Go back to Grupa"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }