<template>
  <div class="dev-onboard">
    <Navbar />
    <main>
      <div class="container-wrapper">
        <div class="page-content-div">
          <div class="center-container">
            <div class="content-title">
              You were invited by
              {{ user.teamLeadName ? user.teamLeadName.split(" ")[0] : "" }} to
              join
              <span>{{ teamName }}</span>
            </div>
            <div class="sub-title">
              Accepting this invite means you’ll be removed from your existing
              team.
            </div>
            <div class="card-container">
              <div
                v-for="item in options"
                :key="item.id"
                class="option-card"
                @click="handleSwapTeam(item.id)"
              >
                <div class="left-content">
                  <div class="card-name">
                    {{ item.name }}
                  </div>
                  <div v-if="item.id == 0" class="card-content">
                    {{ item.content }}
                  </div>
                  <div v-else class="card-content">
                    Accept Invite and join {{ teamName }} to build amazing
                    products on Grupa
                  </div>
                </div>
                <div class="right-content">
                  <img src="/img/onboarding/right-arrow.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/onboarding_navbar";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    handleSwapTeam: {
      required: true,
      type: Function,
    },
    invitedTeam: {
      type: Object,
      required: true,
    },
  },
  components: {
    Navbar,
  },

  data: () => ({
    options: [
      {
        id: 1,
        name: "Accept Invite",
        content: `Accept Invite and join TeamName to build amazing products on Grupa`,
      },
      {
        id: 0,
        name: "Reject Invite",
        content: "Forge your own path on Grupa but not as part of this team.",
      },
    ],
  }),

  created() {},
  methods: {},
  computed: {
    teamName() {
      let invitedTeam = this.invitedTeam;
      if (invitedTeam.team_name) {
        return invitedTeam.team_name;
      } else if (!invitedTeam.team_name && invitedTeam.teamLeadName) {
        return `${invitedTeam.teamLeadName.split(" ")[0]}'s`;
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.container-wrapper {
}
.top-container {
  margin-top: 48px;
  display: flex;
}
.indicator_progress {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 37%;
}
.page-content-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.center-container {
  margin-top: 60px;
  width: 410px;
  text-align: left;
}
.content-title {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.content-title span {
  color: #0781f2;
}
.sub-title {
  margin-top: 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.card-container {
  margin-top: 24px;
}
.option-card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  padding: 24px 30px 24px;
  justify-content: space-between;
  cursor: pointer;
}
.card-name {
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.card-content {
  width: 286px;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 8px;
}
.right-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .center-container {
    width: unset;
  }
}
</style>
