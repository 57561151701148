<template>
  <div class="text-xs-center">
    <v-dialog width="500" v-model="dialog" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <img src="/img/profile/grupa-logo.png" height="60" width="120" />
        </v-card-title>

        <v-subheader>
          <b>Ooops!</b>
        </v-subheader>

        <v-card-text>
          <p style="color: red">{{ dialog_message }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a href="/">Go back to Grupa</a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog_message: {
      type: String,
      required: true,
    },
  },
  components: {},

  data: () => ({
    dialog: true,
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.headline {
  font-family: "Moderat" !important;
  font-size: 14px !important;
  font-weight: 100;
  line-height: 32px !important;
  letter-spacing: 3px !important;
}
</style>
